import type { VFC } from 'react';
import Image from 'next/image';
import Link from 'next/link';

import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import { PLACEHOLDER_EMPTY_STATE } from '@/constants/image';
import { containerCx, imageCx, contentCx } from '../styles';

/**
 * @function NotFound
 */
const NotFound: VFC = () => {
  return (
    <div className={containerCx}>
      <div className={contentCx}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <div className={imageCx}>
              <Image src={PLACEHOLDER_EMPTY_STATE} alt="not found" priority width={480} height={360} unoptimized />
            </div>
          </Grid>
          <Grid item xs={12}>
            <Grid container>
              <Grid item xs={12}>
                <Typography variant="h2" component="h1" align="center">
                  Halaman Tidak Ditemukan
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography variant="body2" component="div" align="center">
                  Halaman yang kamu coba akses belum tersedia saat ini. Silakan kembali untuk tetap menjelajah.
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Link href="/" passHref>
              <Button sx={{ width: '100%' }} type="button" variant="contained" size="large">
                <Typography variant="body1-bold" component="span" align="center">
                  Kembali ke Beranda
                </Typography>
              </Button>
            </Link>
          </Grid>
        </Grid>
      </div>
    </div>
  );
};

export default NotFound;
